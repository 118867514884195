import React from "react";
import styled from "styled-components";
import { Body, Header, SubHeader } from "../Typography";
import HomeHeroImage from "../../images/home/thumbs-up-studio-bg.jpg";
import { SiteContent } from "../Layouts";
import BoxedImage from "../Cards/BoxedImage";

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vw;
  min-height: 800px;
  justify-content: center;
  align-items: center;
  background: url(${HomeHeroImage});
  background-size: cover;
  background-position: bottom;
  margin-bottom: 128px;
`;

const ContentWrapper = styled.div`
  padding: 64px;
  margin: 0 auto;
  text-align: center;
`;

const WorkHero = ({ data }) => {
  return (
    <HeroWrapper>
      <SiteContent column flexStart>
        <ContentWrapper>
          <BoxedImage noBox src={data.displayImage} color={data.color}/>
          <SubHeader
            data-aos="fade-down"
            data-aos-duration={800}
            medium
            noMargin
          >
            Case Study
          </SubHeader>
          <div style={{ height: "16px" }}></div>
          <Header
            h2
            sofia
            data-aos="fade-in"
            data-aos-duration={1200}
            data-aos-delay="400"
          >
            {data.title}
          </Header>
          <Body
            data-aos="fade-up"
            data-aos-duration={1200}
            data-aos-delay="800"
            maxWidth={800}
            fontSize={29}
            lineHeight={39}
            align="center"
          >
            {data.services}
          </Body>
          <Body
            data-aos="fade-up"
            data-aos-duration={1200}
            data-aos-delay="800"
            maxWidth={800}
            fontSize={29}
            lineHeight={39}
            align="center"
          >
            {data.date}
          </Body>
        </ContentWrapper>
      </SiteContent>
    </HeroWrapper>
  );
};

export default WorkHero;
